import React from 'react';
import { Col, Container, ResponsiveEmbed, Row } from 'react-bootstrap';
import { ActionButton, Disqus, Footer, Layout, Navbar, PageHeader, Sponsor } from '@components';
import { site, videoFallback } from '@variables';
import GradHandouts from '@assets/documents/grad/Grad Handouts.pdf';

const GradProgramPage = () => <Layout className="about-page">
  <Navbar />
  <PageHeader title="SoIT - Graduate Program" />

  <Container style={{ marginBottom: `1rem`, marginTop: `3rem` }}>
    <Row className="justify-content-center">
      <Col md={8}>
        <ResponsiveEmbed aspectRatio="16by9" className="mr-md-2 mb-3">
          <iframe
            title="MSIT Presentation"
            src={
              videoFallback ?
                `` :
                `https://player.vimeo.com/video/534082935`
            }
            className="embed-responsive-item"
            allow="autoplay; fullscreen"
            allowFullScreen />
        </ResponsiveEmbed>
      </Col>
    </Row>

    <Row className="justify-content-center mb-3">
      <a href={GradHandouts} download="Graduate Course and Curriculum Sheets.pdf">
        <ActionButton>Course and Curriculum Sheets</ActionButton>
      </a>
      <a
        href="https://cech.uc.edu/schools/it/grad-programs/information-technology-phd.html"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ActionButton>Learn more about our Ph.D. Program</ActionButton>
      </a>
      <a
        // eslint-disable-next-line max-len
        href="https://outlook.office365.com/owa/calendar/EnrollmentServicesCECHattheUniversityofCincinnati@mailuc.onmicrosoft.com/bookings/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <ActionButton>Schedule Appointment with IT Enrollment Advisor</ActionButton>
      </a>
    </Row>
    <Disqus
      identifier="about-grad-2022"
      title="SoIT - Graduate Program"
      pageLocation={`${site.url}/about/grad`}
    />
  </Container>

  <Sponsor />
  <Footer />
</Layout>;

export default GradProgramPage;
